import React, { useState } from "react";

import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import PlaidIDVLink from "~src/components/shared/onboarding/PlaidIDVLink";

type LinkPaymentProps = {
  onNext: () => void;
  onRetryDebit: () => void;
};

const IdentityVerification: React.VFC<LinkPaymentProps> = ({
  onNext,
  onRetryDebit,
}) => {
  const [hideIntro, setHideIntro] = useState(false);

  return (
    <SmallPagePanel
      icon={<></>}
      title={hideIntro ? "" : "Identity Verification"}
      subtitle={
        hideIntro
          ? ""
          : "Almost there! We're required by law to ask you a few more ID verification questions."
      }
    >
      <PlaidIDVLink
        onSuccess={onNext}
        onError={() => setHideIntro(true)}
        onRetryDebit={onRetryDebit}
        hideIntro
        showContinue
      />
    </SmallPagePanel>
  );
};

export default IdentityVerification;
