import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Stack } from "@mui/material";

import RewardCard from "~common/components/cards/RewardCard";
import FullHeightDialog from "~common/components/modals/FullHeightDialog";
import type { UserBillingAddress } from "~common/services";
import { useTrackPageView } from "~common/tracking";
import { setSessionStorageWithFallback } from "~common/utils/browser-storage";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import AddressAutocomplete from "~src/components/shared/onboarding/AddressAutocomplete";
import CurrentUserForm from "~src/components/shared/onboarding/CurrentUserForm";
import { selectClaims, selectCurrentUser } from "~src/store";
import {
  markEmailVerified,
  setAuthUserInfo,
} from "~src/store/slices/user-slice";

const CompleteAccount: React.VFC = () => {
  const dispatch = useDispatch();
  const { rewardCampaign, gift } = useSelector(selectClaims);
  const currentUser = useSelector(selectCurrentUser.data);
  const [address, setAddress] = useState(currentUser?.billing_address || null);
  const [editAddress, setEditAddress] = useState(false);

  useTrackPageView("Complete Account");

  const handleSelectAddress = (value: UserBillingAddress) => {
    setAddress(value);
    setEditAddress(false);
  };

  const handleSuccess = () => {
    setSessionStorageWithFallback("activateCardRequired", "true");
    dispatch(setAuthUserInfo({ activateCardRequired: true }));
    dispatch(markEmailVerified());
  };

  let rewardCard = null;

  if (gift) {
    rewardCard = <RewardCard reward={gift} sx={{ width: 224, mb: 8 }} />;
  }

  if (rewardCampaign) {
    rewardCard = (
      <RewardCard reward={rewardCampaign} sx={{ width: 224, mb: 8 }} />
    );
  }

  return (
    <>
      <SmallPagePanel
        icon={
          <Stack width="100%" alignItems="center">
            {rewardCard}
          </Stack>
        }
        title="Let's create your profile"
      >
        <CurrentUserForm
          isNewUser
          address={address}
          onEditAddress={() => setEditAddress(true)}
          onSuccess={handleSuccess}
        />
      </SmallPagePanel>

      <FullHeightDialog
        heading="Billing Address"
        open={editAddress}
        onClose={() => setEditAddress(false)}
      >
        <AddressAutocomplete onSelect={handleSelectAddress} />
      </FullHeightDialog>
    </>
  );
};

export default CompleteAccount;
