import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import GenericLoadingScreen from "~common/components/loading/GenericLoadingScreen";
import { useGetCurrentUser } from "~common/services/users";
import { getSessionStorageWithFallback } from "~common/utils/browser-storage";
import useFetchAnywhereCardForUser from "~src/hooks/fetching/useFetchAnywhereCardForUser";
import {
  selectAuthSession,
  selectAuthUserInfo,
  selectCatchCard,
  selectCurrentUser,
  selectEmailVerificationCode,
  selectIsAuthed,
  selectIsAuthedVerified,
  selectIsLoading,
} from "~src/store";
import { currentUserActions } from "~src/store/slices/services/currentUser-slice";
import { setAuthUserInfo } from "~src/store/slices/user-slice";
import { hasValidApplicationData } from "~src/utils/catch-card";

type AppRouteHandlerProps = {
  authRequired?: boolean;
  authFlow?: boolean;
};

// This component controls redirects and refresh loading before rendering
// the actual component for a given route, and thereby ensures the user
// is seeing the right component for their route and local state.

const AppRouteHandler: React.FC<AppRouteHandlerProps> = ({
  authRequired = false,
  authFlow = false,
  children,
}) => {
  const dispatch = useDispatch();
  const isAuthedVerified = useSelector(selectIsAuthedVerified);
  const authSession = useSelector(selectAuthSession);
  const isAuthed = useSelector(selectIsAuthed);
  const authUserInfo = useSelector(selectAuthUserInfo);
  const isLoading = useSelector(selectIsLoading);
  const emailVerificationCode = useSelector(selectEmailVerificationCode);
  const location = useLocation();
  const catchCard = useSelector(selectCatchCard);
  const currentUser = useSelector(selectCurrentUser.data);
  const { data: initialCurrentUser } = useGetCurrentUser({
    lazy: !isAuthed || !!currentUser,
    queryParams: {
      include_billing_address: true,
    },
  });

  useFetchAnywhereCardForUser();

  useEffect(() => {
    dispatch(
      setAuthUserInfo({
        activateCardRequired: !!getSessionStorageWithFallback(
          "activateCardRequired"
        ),
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (initialCurrentUser) {
      dispatch(currentUserActions.manualSet(initialCurrentUser));
    }
  }, [dispatch, initialCurrentUser]);

  if (
    isLoading ||
    (isAuthed &&
      authUserInfo?.activateCardRequired &&
      (!currentUser || !catchCard))
  ) {
    return <GenericLoadingScreen isFullscreen />;
  }

  // Path routes for navigation.
  const to = {
    Home: "/home",
    SignIn: "/sign-in",
    AnswerOTP: "/sign-in/answer-otp",
    CompleteAccount: "/sign-in/complete-account",
    ActivateCard: "/sign-in/activate-card",
    VerifyEmail: "/sign-in/verify-email",
    CheckEmailVerification: (code: string) => `/verify/email/${code}`,
  };

  if (authRequired && !isAuthedVerified) {
    return <Navigate to={to.SignIn} replace />;
  }

  // We need to check whether the redirect is idempotent in a nested
  // condition to (a) preserve the conditiona flow of else ifs and
  // (b) allow the idempotent page to return {children}.

  const alreadyAt = (path: string) => location.pathname === path;

  if (authFlow) {
    if (!isAuthed && !authSession) {
      if (!alreadyAt(to.SignIn)) {
        return <Navigate to={to.SignIn} replace />;
      }
    } else if (!isAuthed) {
      if (!alreadyAt(to.AnswerOTP)) {
        return <Navigate to={to.AnswerOTP} replace />;
      }
    } else if (
      !authUserInfo ||
      !authUserInfo.email ||
      (authUserInfo?.activateCardRequired &&
        !hasValidApplicationData(currentUser))
    ) {
      if (!alreadyAt(to.CompleteAccount)) {
        return <Navigate to={to.CompleteAccount} replace />;
      }
    } else if (
      isAuthed &&
      authUserInfo?.activateCardRequired &&
      (catchCard?.user_flow_status !== "approved" || !catchCard?.is_pin_set)
    ) {
      if (!alreadyAt(to.ActivateCard)) {
        return <Navigate to={to.ActivateCard} replace />;
      }
    } else if (!isAuthedVerified && !emailVerificationCode) {
      if (!alreadyAt(to.VerifyEmail)) {
        return <Navigate to={to.VerifyEmail} replace />;
      }
    } else if (!isAuthedVerified && emailVerificationCode) {
      return (
        <Navigate
          to={to.CheckEmailVerification(emailVerificationCode)}
          replace
        />
      );
    } else {
      return <Navigate to={to.Home} replace />;
    }
  }

  return <>{children}</>;
};

AppRouteHandler.defaultProps = {
  authRequired: false,
  authFlow: false,
};

export default AppRouteHandler;
