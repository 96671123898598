import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { removeSessionStorageWithFallback } from "~common/utils/browser-storage";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import CatchCardSuccess from "~src/components/shared/onboarding/CatchCardSuccess";
import { setAuthUserInfo } from "~src/store/slices/user-slice";
import { getDevice } from "~src/utils/devices";

const Success: React.VFC = () => {
  const dispatch = useDispatch();
  const device = getDevice();
  const navigate = useNavigate();

  const handleClose = () => {
    removeSessionStorageWithFallback("activateCardRequired");
    dispatch(setAuthUserInfo({ activateCardRequired: false }));
    navigate("/home");
  };

  return (
    <SmallPagePanel
      icon={<></>}
      title={`Add it to ${
        device === "android" ? "your digital wallet" : "Apple Pay"
      } so you can use it in-store`}
      subtitle="Groceries, gas, dr appt... 1.5% back anywhere"
    >
      <CatchCardSuccess onClose={handleClose} />
    </SmallPagePanel>
  );
};

export default Success;
