import React, { useCallback, useEffect, useState } from "react";

import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import PinForm from "~src/components/shared/onboarding/PinForm";

type PinSetupProps = {
  onNext: () => void;
};

const PinSetup: React.VFC<PinSetupProps> = ({ onNext }) => {
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        onNext();
      }, 5000);
    }
  }, [success, onNext]);

  const handleSuccess = useCallback(() => {
    setSuccess(true);
  }, []);

  return (
    <SmallPagePanel
      icon={<></>}
      title="Set Up Your PIN"
      subtitle="Stores might ask for it when you use your Catch card in person with Apple Pay. Make sure you can memorize it or write it down."
    >
      <PinForm onSuccess={handleSuccess} />
    </SmallPagePanel>
  );
};

export default PinSetup;
