import React, { useCallback, useEffect, useState } from "react";

import FullHeightDialog from "~common/components/modals/FullHeightDialog";
import CatchCardApplication from "~src/components/shared/onboarding/CatchCardApplication";

type ConfirmationProps = {
  open: boolean;
  onNext: () => void;
};

const Confirmation: React.VFC<ConfirmationProps> = ({ open, onNext }) => {
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        onNext();
      }, 5000);
    }
  }, [success, onNext]);

  const handleSuccess = useCallback(() => {
    setSuccess(true);
  }, []);

  return (
    <FullHeightDialog
      heading={success ? "Success!" : "Confirming..."}
      open={open}
    >
      {open && <CatchCardApplication onSuccess={handleSuccess} showContinue />}
    </FullHeightDialog>
  );
};

export default Confirmation;
