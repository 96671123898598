import React, { useState } from "react";

import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import LinkDebit from "~src/components/shared/onboarding/LinkDebit";

type LinkPaymentProps = {
  onNext: () => void;
};

const LinkPayment: React.VFC<LinkPaymentProps> = ({ onNext }) => {
  const [hideIntro, setHideIntro] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <SmallPagePanel
      icon={<></>}
      title={hideIntro ? "" : "Link your existing debit card"}
      subtitle={
        hideIntro
          ? ""
          : "The Catch card is like an extension to your existing debit card. We'll charge your linked debit card when you pay with your Catch card."
      }
    >
      <LinkDebit
        onError={setHideIntro}
        onEdit={setIsEditing}
        isEditing={isEditing}
        onSuccess={onNext}
      />
    </SmallPagePanel>
  );
};

export default LinkPayment;
