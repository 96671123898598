import React, { useEffect, useState } from "react";

import { Box, Stack, Typography } from "@mui/material";

import catchCash from "~common/assets/illustrations/catch_cash.png";
import CatchCard from "~common/components/cards/CatchCard";
import { PrimaryButton } from "~common/components/controls/buttons";
import { PartyPopperIcon, SparkleIcon } from "~common/components/icons/vector";
import { useTracking } from "~common/tracking";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import CatchCardDisclosures from "~src/components/shared/onboarding/CatchCardDisclosures";

type EducationProps = {
  onNext: () => void;
};

const Education: React.VFC<EducationProps> = ({ onNext }) => {
  const { trackEvent } = useTracking();
  const [startTransform, setStartTranform] = useState(false);

  useEffect(() => {
    void (async () => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      setStartTranform(true);
    })();
  }, []);

  const handleClick = () => {
    trackEvent("Onboarding Education Activate Card");
    onNext();
  };

  return (
    <SmallPagePanel
      title="You're ready to activate your Catch debit card!"
      subtitle="It only takes a minute."
      icon={<></>}
    >
      <Box position="relative">
        <Stack
          alignItems="flex-end"
          px={8}
          mt={-7}
          mb={-15}
          color="tertiary.main"
        >
          <CatchCard
            sx={({ palette }) => ({
              width: 138,
              transform: "rotate(-7.5deg)",
              boxShadow: `3px 3px 0px 0px ${palette.tints?.purple?.main || ""}`,
            })}
          />

          {[...Array(3).keys()].map((i) => (
            <SparkleIcon
              key={i}
              size="original"
              sx={{
                position: "absolute",
                transform: `scale(${startTransform ? 1 : 0})`,
                transition: "transform 0.125s ease-in",
                ...(i === 0 && {
                  width: 25,
                  height: 25,
                  top: -54,
                  right: 156,
                  transitionDelay: "0.15s",
                }),
                ...(i === 1 && {
                  width: 38,
                  height: 38,
                  top: 72,
                  right: 36,
                  transitionDelay: "0.3s",
                }),
                ...(i === 2 && {
                  width: 22,
                  height: 22,
                  position: "absolute",
                  top: -20,
                  right: 4,
                  transitionDelay: "0.45s",
                }),
              }}
            />
          ))}
        </Stack>
      </Box>

      <Stack spacing={6}>
        <Stack
          spacing={6}
          sx={({ palette, shape }) => ({
            background: palette.tertiary.light,
            borderRadius: `${shape.borderRadius * 3}px`,
            px: 4,
            pt: 10,
            pb: 6,
            strong: {
              fontSize: 22,
              fontWeight: 950,
              lineHeight: 1.45,
              letterSpacing: "-0.01em",
              verticalAlign: -2,
              mr: 1,
            },
          })}
        >
          <Stack spacing={1.5} direction="row" alignItems="flex-start">
            <Box component="img" src={catchCash} width={38} />

            <Stack spacing={2} pt={0.5}>
              <Typography variant="bodyRegular">
                <strong>Catch Cash</strong> on every purchase
              </Typography>

              <Typography variant="bodyRegular">
                <strong>5-15%</strong> at Catch brands
              </Typography>

              <Typography variant="bodyRegular">
                <strong>1.5%</strong> everywhere else
              </Typography>
            </Stack>
          </Stack>

          <Stack spacing={1.5} direction="row" alignItems="flex-start">
            <Box
              color="tertiary.main"
              sx={{
                "& > .MuiBox-root": {
                  width: 38,
                  height: 38,
                  display: "block",
                },
              }}
            >
              <PartyPopperIcon size="small" />
            </Box>

            <Typography variant="bodyRegular" pt={0.5}>
              <strong>$100s</strong> in reward drops / month
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <PrimaryButton onClick={handleClick}>Activate my card</PrimaryButton>

      <CatchCardDisclosures
        sx={({ palette }) => ({
          background: palette.grey[200],
          color: palette.grey[500],
        })}
      />
    </SmallPagePanel>
  );
};

export default Education;
